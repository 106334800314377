import React, { useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, IconSpinner } from '../../components';
import { camelCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getDIMRAttributes } from '../../ducks/dimr.duck';
import { DIMRAttributes } from '../EditListingPage/EditListingWizard/EditListingDetailsPanel/EditListingDetailsForm/ArticleAttributeFields';
import { getConditionForBulk, getDIMRFieldsWithResolvedValues } from '../../util/listing';
import useInterfaceLang from '../../hooks/useInterfaceLang';
import { LOCALE, getCurrentLocale } from '../../util/locale';

import css from './ListingPage.module.css';
import { isMarketplaceSecondLife } from '../../util/marketplace';
import { useConfiguration } from '../../context/configurationContext';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;

  const dispatch = useDispatch();
  const config = useConfiguration();
  const { dimrAttributes, getDIMRAttributesInProgress, getDIMRAttributesError } = useSelector(
    state => state.dimr
  );
  const { interfaceLang } = useInterfaceLang();

  const is2ndLifeMarketplace = isMarketplaceSecondLife(config);

  const { listingFields } = listingConfig || {};

  const { articleNumber } = publicData || {};

  const locale = getCurrentLocale();
  const isBrazilLocale = locale === LOCALE.BRAZIL;
  const isUSLocale = locale === LOCALE.UNITED_STATES;

  useEffect(() => {
    if (!articleNumber || is2ndLifeMarketplace) return;

    dispatch(getDIMRAttributes(articleNumber));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleNumber, is2ndLifeMarketplace]);

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      if (optionConfig) {
        switch (key) {
          // Condition for some regions has a special grading translation
          case 'condition':
            if (isBrazilLocale) {
              // Condition is "Always new" for listings posted on Brazil region
              optionConfig.label = 'General.gradeALongBrazil';
            } else if (isUSLocale) {
              optionConfig.label = getConditionForBulk(optionConfig.option, intl);
            }
            break;

          default:
            break;
        }
      }

      return schemaType === 'enum' || schemaType === 'radio'
        ? filteredConfigs.concat({
            key,
            value: optionConfig?.label
              ? intl.formatMessage({ id: optionConfig?.label })
              : value
              ? intl.formatMessage({ id: `General.${camelCase(value)}` })
              : '',
            label: intl.formatMessage({ id: label }),
          })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({
            key,
            value: getBooleanMessage(value),
            label,
          })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label: intl.formatMessage({ id: label }) })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);

  const dimrResolvedValues = dimrAttributes?.length
    ? getDIMRFieldsWithResolvedValues(intl, dimrAttributes, interfaceLang).reduce(
        (fields, field) => {
          const { key, value } = field;

          fields[key] = value;

          return fields;
        },
        {}
      )
    : {};

  return existingListingFields.length > 0 || dimrAttributes?.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields?.map(detail => (
          <li key={detail.key} className={css.detailsRow}>
            <span className={css.detailLabel}>{detail.label}</span>
            <span>{detail.value}</span>
          </li>
        ))}
        {getDIMRAttributesInProgress ? (
          <li>
            <IconSpinner />
          </li>
        ) : getDIMRAttributesError ? (
          <li className={css.errorText}>
            <FormattedMessage id="General.showListingFailed" />
          </li>
        ) : (
          <li className={css.dimrFieldsWrapper}>
            <DIMRAttributes
              dimrAttributes={dimrAttributes}
              dimrKeysWithResolvedValues={dimrResolvedValues}
              className={css.dimrFields}
            />
          </li>
        )}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
